<template>
  <section
    v-loading="loading"
    class="custom-dashboard-table"
    :element-loading-text="loadingText"
  >
    <el-row :gutter="5">
      <el-col
        v-if="componentData && componentData.component_name"
        :span="6"
        class="mt-1"
        style="padding-left: 10px"
      >
        <el-tooltip
          placement="top-start"
          :content="componentData.component_name"
        >
          <b>
            {{ componentData.component_name | truncate(15, "...") }}
          </b>
        </el-tooltip>
      </el-col>
      <el-col
        :span="5"
        v-if="componentData.date_filter_field"
        :offset="
          componentData.selected_date_picker == 'custom_date' ||
          componentData.selected_date_picker == 'custom_date_range'
            ? checkIsDate
              ? 1
              : 2
            : checkIsDate
            ? 4
            : 8
        "
      >
        <el-select
          :placeholder="'Pick a date'"
          v-model="componentData.selected_date_picker"
          @change="applyDateFilter"
          clearable
          size="mini"
          style="margin-top: 9px"
        >
          <el-option
            v-for="(el, index) in pickerOptions"
            :key="el.value + '_' + index"
            :value="el.value"
            :label="el.label"
            >{{ el.label }}</el-option
          >
        </el-select>
      </el-col>
      <el-col
        :span="4"
        v-if="
          componentData.selected_date_picker == 'custom_date' ||
          componentData.selected_date_picker == 'custom_date_range'
        "
      >
        <el-date-picker
          v-if="componentData.selected_date_picker == 'custom_date'"
          v-model="componentData.custom_date"
          size="mini"
          placeholder="Custom date"
          @change="applyCustomDateFilter"
          format="MM-dd-yyyy"
          style="margin-top: 9px; width: 100%"
        ></el-date-picker>
        <el-date-picker
          v-if="componentData.selected_date_picker == 'custom_date_range'"
          v-model="componentData.custom_date_range"
          size="mini"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          type="daterange"
          @change="applyCustomDateRangeFilter"
          format="MM-dd-yyyy"
          style="margin-top: 9px; width: 100%"
        ></el-date-picker>
      </el-col>
      <el-col
        v-if="checkIsDate"
        :span="4"
        :offset="
          !componentData.date_filter_field
            ? componentData.selected_date_picker == 'custom_date' ||
              componentData.selected_date_picker == 'custom_date_range'
              ? 0
              : 9
            : 0
        "
      >
        <el-select
          placeholder="Type"
          size="mini"
          style="margin-top: 9px"
          v-model="componentData.selectedDateRange"
          @change="updateDateRange"
        >
          <el-option
            v-for="(el, index) in dateTypeOptions"
            :key="el.key + '_' + index"
            :value="el.key"
            :label="el.name"
            >{{ el.name }}</el-option
          >
        </el-select>
      </el-col>
      <el-col
        :span="4"
        :offset="!componentData.date_filter_field ? (checkIsDate ? 0 : 13) : 0"
        class="d-flex"
      >
        <el-select
          v-model="componentData.chart_type"
          filterable
          default-first-option
          size="mini"
          style="margin-top: 9px"
          @change="updateChartType"
        >
          <el-option
            v-for="(field, index) of chartTypes"
            :value="field.value"
            :key="field.value + index"
            :label="field.label"
          >
            <span>{{ field.label }}</span>
          </el-option>
        </el-select>
        <el-dropdown trigger="click" v-if="!hide_options">
          <el-link
            icon="el-icon-more"
            style="transform: rotate(90deg); height: 40px"
            :underline="false"
            class="mt-"
          ></el-link>
          <el-dropdown-menu slot="dropdown">
            <a @click="openConfigDialog">
              <el-dropdown-item>Edit</el-dropdown-item>
            </a>
            <a @click="deleteComponent">
              <el-dropdown-item>Delete</el-dropdown-item>
            </a>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="1" class="icon-style" v-if="!hide_expend">
        <el-button
          icon="el-icon-full-screen"
          type="text"
          v-if="componentData.isExpandTable === true"
          @click="expendTable"
        ></el-button>
        <el-button
          icon="el-icon-full-screen"
          type="text"
          v-else
          @click="expendTable"
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="5">
      <el-col
        :offset="12"
        :span="12"
        v-if="checkIsDate && componentData && componentData.selectedDateRange"
        class="d-flex"
      >
        <el-date-picker
          v-model="componentData.startYear"
          type="year"
          :placeholder="
            componentData.selectedDateRange == 'YEARLY'
              ? 'Start year'
              : 'Select year'
          "
          size="mini"
          style="width: 100%; margin-right: 5px"
          :picker-options="startDateOptions"
          @change="updateDateRange"
        >
        </el-date-picker>
        <el-date-picker
          v-if="componentData && componentData.selectedDateRange == 'YEARLY'"
          v-model="componentData.endYear"
          type="year"
          placeholder="End year"
          size="mini"
          style="width: 100%"
          :picker-options="endDateOptions"
          @change="updateDateRange"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row>
      <div
        style="display: flex"
        v-if="currentFilter && currentFilter.quick_filters"
      >
        <QuickEntityFilters
          class="ml-1"
          v-if="currentFilter && currentFilter.quick_filters"
          :key="
            componentData.entity_id +
            '_' +
            index +
            '_' +
            componentData.filter_id +
            '_table_filters'
          "
          :filterFields="currentFilter.quick_filters"
          :allFields="currentEntityFields"
          :selectedFileds="selectedFileds"
          @quick-filter-updated="applyQuickFilter"
          :fromCD="true"
        ></QuickEntityFilters>
      </div>
    </el-row>
    <div
      class="pie-body"
      v-if="
        chartData && chartData.labels && chartData.labels.length && !refresh
      "
    >
      <Bar
        v-if="componentData.chart_type == 'BAR' && !refresh"
        :chart-options="chartOptionsForPiechart"
        :chart-data="chartData"
      />
      <Doughnut
        v-else-if="componentData.chart_type == 'DOUGHNUT' && !refresh"
        :chart-options="chartOptions"
        :chart-data="chartData"
      />
      <LineChart
        v-else-if="componentData.chart_type == 'LINE' && !refresh"
        :chart-options="chartOptionsForPiechart"
        :chart-data="chartData"
      />
      <Pie
        v-else-if="!refresh"
        :chart-options="chartOptions"
        :chart-data="chartData"
      />
      <span
        v-if="
          componentData &&
          componentData.component_name &&
          componentData.chart_type != 'BAR'
        "
        class="mt-1 mb-1"
        >{{ componentData.component_name }}</span
      >
    </div>
    <div v-else class="pie-no-data">
      <span>No records found</span>
    </div>
    <dialog-component
      :visible="openDialogVisible"
      :title="'Edit' + 'Component'"
    >
      <div v-loading="loading">
        <CustomComponentEdit
          :addStatsData="updateComponentData"
          :selectedEntity="currentEntity"
          :globalVariablesData="globalVariablesData"
          :getRelationshipEntities="getRelationshipEntities"
        ></CustomComponentEdit>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openDialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="updateComponent"
          :disabled="checkDisabled"
          >Save</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :title="component.component_name"
      :visible="tableVisible"
      @before-close="handleDialogClose"
    >
      <div style="max-height: 70vh; overflow-y: auto; scrollbar-width: thin">
        <CustomDashboardTable
          v-if="tableVisible"
          :component="component"
          :entity_id="this.componentData.entity_id"
          :newFilters="filters"
          :customFilters="[]"
          :hide_options="true"
          :hide_expend="true"
          :isFromApplicationuser="isFromApplicationuser"
          :hasViewPermission="hasViewPermission"
          :hasEditPermission="hasEditPermission"
        ></CustomDashboardTable>
      </div>
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
// import { Pie, Bar, Doughnut } from "vue-chartjs/legacy";
import { bus } from "../../main";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import moment from "moment";
import axios from "@/config/axios";
// import {
//   Chart as ChartJS,
//   // Title,
//   Tooltip,
//   // Legend,
//   ArcElement,
//   CategoryScale,
//   LinearScale,
//   BarElement,
// } from "chart.js";
// ChartJS.register(
//   // Title,
//   Tooltip,
//   // Legend,
//   ArcElement,
//   CategoryScale,
//   LinearScale,
//   BarElement
// );
export default {
  props: {
    component: Object,
    hide_options: Boolean,
    hide_expend: Boolean,
    index: Number,
    isFromApplicationuser: Boolean,
    customDashboardData: Object,
    globalVariablesData: Array,
    getRelationshipEntities: Array,
    hasViewPermission: Boolean,
    hasEditPermission: Boolean,
  },
  components: {
    Pie: () => import("./charts/pie.vue"),
    Bar: () => import("./charts/bar.vue"),
    Doughnut: () => import("./charts/doughnut.vue"),
    LineChart: () => import("./charts/lineChart.vue"),
    CustomComponentEdit: () => import("./customComponentEdit.vue"),
    CustomDashboardTable: () => import("./customDashboardTable.vue"),
    QuickEntityFilters: () =>
      import("@/components/widgets/entityQuickFilters.vue"),
    // CustomDashboardFilters: () => import("./customDashboardFilters.vue"),
  },
  mixins: [CustomDashboardConfig],
  computed: {
    getPieStyles() {
      return {};
    },
    checkDisabled() {
      return (
        !this.updateComponentData.component_name ||
        !this.updateComponentData.entity_id
      );
    },
    checkIsDate() {
      let selectedField = (this.currentEntityFields || []).find(
        (e) => e.key == this.componentData.pie_chart_field
      );
      return (selectedField && selectedField.inputType == "DATE") ||
        (selectedField &&
          selectedField.inputType == "FORMULA" &&
          selectedField.result_type == "DATE")
        ? true
        : false;
    },
    startDateOptions() {
      if (
        this.componentData?.endYear &&
        this.componentData?.selectedDateRange == "YEARLY"
      ) {
        return {
          disabledDate: (year) => {
            return (
              new Date(year).getFullYear() >=
              new Date(this.componentData.endYear).getFullYear()
            );
          },
        };
      }
      return {};
    },
    endDateOptions() {
      if (this.componentData?.startYear) {
        return {
          disabledDate: (year) => {
            return (
              new Date(year).getFullYear() <=
              new Date(this.componentData.startYear).getFullYear()
            );
          },
        };
      }
      return {};
    },
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    ...mapGetters("auth", ["getGlobalFiltervalue"]),
  },
  data() {
    return {
      loading: false,
      loadingText: "Calculating...",
      chartData: {
        labels: [],
        datasets: [
          // {
          //   backgroundColor: [],
          //   data: [],
          //   label: "",
          // },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.graphClicked,
      },
      chartOptionsForPiechart: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.graphClicked,
        plugins: {
          tooltip: {
            position: "nearest",
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";
                if (label) {
                  label += ": ";
                }
                if (
                  context.parsed.y !== null &&
                  context.dataset.currency_field?.inputType == "CURRENCY" &&
                  context?.dataset?.currency_field?.validations
                ) {
                  let type = context.dataset.currency_field.validations.currency
                    ? context.dataset.currency_field.validations.currency
                    : "USD";
                  let locale = context.dataset.currency_field.validations.locale
                    ? context.dataset.currency_field.validations.locale
                    : "en-US";
                  label += new Intl.NumberFormat(locale, {
                    style: "currency",
                    currency: type,
                  }).format(context.parsed.y);
                } else {
                  label += context.parsed.y;
                }
                return label;
              },
            },
          },
        },
      },
      updateComponentData: {},
      openDialogVisible: false,
      componentData: {},
      chartTypes: [
        {
          label: "Pie",
          value: "PIE",
        },
        {
          label: "Bar",
          value: "BAR",
        },
        {
          label: "Doughnut",
          value: "DOUGHNUT",
        },
        {
          label: "Line",
          value: "LINE",
        },
      ],
      colors: [
        "#57e2cf",
        "#57aee3",
        "#5669e2",
        "#8b57df",
        "#8b56e2",
        "#cf56e3",
        "#e256ad",
        "#e35667",
        "#e48851",
        "#e2cf56",
        "#ade256",
        "#66e259",
        "#56e289",
        "#69d4ce",
        "#cff57b",
        "#94dafb",
        "#fc8080",
        "#6d838e",
        "#279ffd",
        "#26e7a6",
        "#fdbc3a",
        "#f9b1b2",
        //"#8b75d7"--purple light,
        "#36bdf7",
        "#8a3e88",
        "#a10b6a",
        "#bd7ebe",
        "#113a70",
        "#df8879",
        "#244985",
        "#7a3896",
        "#9c1c8b",
      ],
      baseColor: "#65cff0",
      refresh: false,
      selected_date_picker: "",
      pickerOptions: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Last week",
          value: "last_week",
        },
        {
          label: "This week",
          value: "this_week",
        },
        {
          label: "Next week",
          value: "next_week",
        },
        {
          label: "Last month",
          value: "last_month",
        },
        {
          label: "This month",
          value: "this_month",
        },
        {
          label: "Next month",
          value: "next_month",
        },
        {
          label: "Last quarter",
          value: "last_quarter",
        },
        {
          label: "This quarter",
          value: "this_quarter",
        },
        {
          label: "Next quarter",
          value: "next_quarter",
        },
        {
          label: "Last year",
          value: "last_year",
        },
        {
          label: "This year",
          value: "this_year",
        },
        {
          label: "Next year",
          value: "next_year",
        },
        {
          label: "Custom date",
          value: "custom_date",
        },
        {
          label: "Custom date range",
          value: "custom_date_range",
        },
      ],
      dateFilter: {
        filter_field: "",
        selected_date_picker: "",
        entity_id: "",
        custom_date: "",
        custom_date_range: [],
      },
      tableVisible: false,
      filters: [],
      currentEntity: null,
      currentEntityFields: [],
      rawData: {},
      savedColors: [],
      selectedDateRange: "YEARLY",
      currentYearSpan: [],
      currentyear: "",
      dateTypeOptions: [
        {
          name: "Year range",
          key: "YEARLY",
        },
        {
          name: "Specific year",
          key: "MONTHLY",
        },
      ],
      startYear: "",
      endYear: "",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      entityData: [],
      currentFilter: null,
      selectedFileds: {},
      applied_quick_filters: [],
      applied_date_filters: [],
      customDashboardPieStats: null,
    };
  },
  beforeDestroy() {
    bus.$off("apply-custom-filter", (data) => {
      console.log("data destory", data);
    });
  },
  async mounted() {
    this.componentData = JSON.parse(JSON.stringify(this.component));
    let filters = [];
    if (this.component.customFilers && this.component.customFilers.length) {
      filters = [...this.component.customFilers];
    }
    if (!this.componentData?.selectedDateRange) {
      this.$set(this.componentData, "selectedDateRange", "YEARLY");
    } else if (this.componentData?.startYear && this.componentData?.endYear) {
      if (this.componentData?.selectedDateRange == "YEARLY") {
        for (
          let st = moment(this.componentData.startYear).format("YYYY");
          st <= moment(this.componentData.endYear).format("YYYY");
          st++
        ) {
          this.currentYearSpan.push(st.toString());
        }
      } else {
        this.currentyear = moment(this.componentData.startYear).format("YYYY");
      }
    } else if (
      this.componentData?.startYear &&
      this.componentData?.selectedDateRange == "MONTHLY"
    ) {
      this.currentyear = moment(this.componentData.startYear).format("YYYY");
    }
    if (
      this.componentData?.selected_date_picker &&
      this.componentData?.date_filter_field
    ) {
      filters = [
        ...filters,
        ...[this.getDatePickerFilter(this.componentData.selected_date_picker)],
      ];
    }
    await this.getNecessaryInfo(filters);
    // bus.$on("apply-custom-filter", (data) => {
    //   if (data) {
    //     if (data.reset) {
    //       this.getNecessaryInfo();
    //     } else if (data.fields) {
    //       let selectedFilters = data.fields.filter(fl => fl.entity_id === this.componentData.entity_id);
    //       if(selectedFilters && selectedFilters.length){
    //         this.$set(this.componentData, 'customFilers', selectedFilters)
    //         this.getNecessaryInfo(selectedFilters);
    //       }
    //     }
    //   }
    // });
  },
  watch: {
    getGlobalFiltervalue: {
      handler() {
        if (
          this.checkGlobalFilterOnSingleStat(this.componentData.entity_id)
            ?.apply_on
        ) {
          this.getNecessaryInfo();
        }
      },
    },
  },
  methods: {
    expendTable() {
      this.componentData.isExpandTable = !this.componentData.isExpandTable;
      this.$emit("expendTable", this.index);
    },
    applyQuickFilter() {
      let filters = [];
      for (const property in this.selectedFileds) {
        let value = this.selectedFileds[property] || [];
        if (typeof value == "object") {
          value = value.filter((e) => e !== "ALL");
          if (value && value.length) {
            filters.push({
              field: property,
              operator: "in",
              value: value,
              value_type: "",
              value_source: "",
              data_type: "STRING",
              query_type: "AND",
              value_field: "",
              validations: {
                data_type: "",
              },
              data_source: "field_option",
            });
          }
        } else {
          if (
            [
              "today",
              "yesterday",
              "last_week",
              "this_week",
              "next_week",
              "last_month",
              "this_month",
              "next_month",
              "last_quarter",
              "this_quarter",
              "next_quarter",
              "last_year",
              "this_year",
              "next_year",
              "custom_date",
              "custom_date_range",
            ].includes(value)
          ) {
            let values = this.getDatePickerHelper(value);
            filters.push({
              field: property,
              operator: "real_time",
              value: values.value,
              value_type: "",
              value_source: "",
              data_type: "DATE",
              query_type: "AND",
              value_field: "",
              validations: {
                data_type: "",
              },
              selected_date_picker: value,
              today: values.today,
              every_year: false,
            });
          }
        }
      }
      if (this.selected_date_picker) {
        let values = this.getDatePickerHelper(this.selected_date_picker);
        filters.push({
          field: this.componentData.date_filter_field,
          operator: "real_time",
          value: values.value,
          value_type: "",
          value_source: "",
          data_type: "DATE",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          selected_date_picker: this.selected_date_picker,
          today: values.today,
          every_year: this.componentData.ignore_year || false,
        });
      }
      this.applied_quick_filters = [];
      this.applied_quick_filters = [...filters, ...this.applied_date_filters];
      filters = [...filters, ...this.applied_date_filters];
      this.getNecessaryInfo(filters);
    },
    checkFieldType(key, type) {
      let selectedField = (this.currentEntityFields || []).find(
        (e) => e.key == key
      );
      return selectedField && selectedField.inputType == type ? true : false;
    },
    updateDateRange() {
      if (
        this.componentData &&
        this.componentData.selectedDateRange == "YEARLY"
      ) {
        if (!this.componentData?.startYear || !this.componentData?.endYear) {
          return;
        }
        this.currentYearSpan = [];
        for (
          let st = new Date(this.componentData.startYear).getFullYear();
          st <= new Date(this.componentData.endYear).getFullYear();
          st++
        ) {
          this.currentYearSpan.push(st.toString());
        }
      } else {
        if (!this.componentData.startYear) {
          return;
        }
        this.currentyear = new Date(this.componentData.startYear)
          .getFullYear()
          .toString();
      }
      this.getNecessaryInfo();
    },
    graphClicked(evt, array) {
      if (array[0] && array[0].index > -1) {
        this.filters = [];
        let filterObj = {};
        if (this.componentData?.pie_chart_field_group_by) {
          let groupFilter = this.createFilter(
            this.componentData?.pie_chart_field_group_by,
            evt,
            array,
            true
          );
          if (groupFilter) {
            this.filters.push(groupFilter);
          }
        }
        if (this.checkIsDate) {
          if (
            this.componentData &&
            this.componentData.selectedDateRange == "YEARLY"
          ) {
            let year = this.chartData?.labels[array[0].index];
            filterObj = {
              value: [
                new Date(year - 1, 11, 31, 18, 0, 0, 0),
                new Date(year, 11, 30, 18, 0, 0, 0),
              ],
              field: this.componentData.pie_chart_field,
              operator: "between",
              value_type: "",
              value_source: "",
              data_type: "DATE",
              query_type: "AND",
              value_field: "",
              validations: {
                data_type: "",
              },
              relation_entity_id: "",
              data_source: "field_option",
            };
          } else {
            let month = this.months.findIndex(
              (e) => e == this.chartData?.labels[array[0].index]
            );
            filterObj = {
              value: [
                new Date(this.currentyear, month, 1, 18, 0, 0, 0),
                new Date(
                  this.currentyear,
                  month,
                  new Date(this.currentyear, month, 0).getDate(),
                  18,
                  0,
                  0,
                  0
                ),
              ],
              field: this.componentData.pie_chart_field,
              operator: "between",
              value_type: "",
              value_source: "",
              data_type: "DATE",
              query_type: "AND",
              value_field: "",
              validations: {
                data_type: "",
              },
              relation_entity_id: "",
              data_source: "field_option",
            };
          }
          this.filters.push(filterObj);
        } else {
          let mainFilter = this.createFilter(
            this.componentData?.pie_chart_field,
            evt,
            array
          );
          if (mainFilter) {
            this.filters.push(mainFilter);
          }
        }
        this.filters = [
          ...this.filters,
          ...this.applied_quick_filters,
          ...this.applied_date_filters,
        ];
        this.tableVisible = true;
        // let label =
        //   this.chartData?.labels && this.chartData?.labels[array[0].index]
        //     ? this.chartData?.labels[array[0].index]
        //     : evt?.chart?.legend?.legendItems &&
        //       evt?.chart?.legend?.legendItems[array[0].index] &&
        //       evt?.chart?.legend?.legendItems[array[0].index].text
        //     ? evt?.chart?.legend?.legendItems[array[0].index].text
        //     : "";
        // let raw =
        //   this.rawData?.labels && this.rawData?.labels[array[0].index]
        //     ? this.rawData?.labels[array[0].index]
        //     : evt?.chart?.legend?.legendItems &&
        //       evt?.chart?.legend?.legendItems[array[0].index] &&
        //       evt?.chart?.legend?.legendItems[array[0].index].text
        //     ? evt?.chart?.legend?.legendItems[array[0].index].text
        //     : "";
        // if (raw && raw.includes("#") && raw.split("#")[1]) {
        //   label = raw.split("#")[1];
        // }
        // if (label) {
        //   this.filters.push({
        //     field: this.componentData.pie_chart_field,
        //     operator: "in",
        //     value: [label],
        //     value_type: "",
        //     value_source: "",
        //     data_type: "STRING",
        //     query_type: "AND",
        //     value_field: "",
        //     validations: {
        //       data_type: "",
        //     },
        //     relation_entity_id: "",
        //     data_source: "field_option",
        //   });
        // if (this.componentData?.pie_chart_field_group_by) {
        //   let groupByObj =
        //     this.chartData?.datasets &&
        //     this.chartData?.datasets[array[0].datasetIndex]
        //       ? this.chartData?.datasets[array[0].datasetIndex]
        //       : evt?.chart?.legend?.legendItems &&
        //         evt?.chart?.legend?.legendItems[array[0].datasetIndex] &&
        //         evt?.chart?.legend?.legendItems[array[0].datasetIndex].text
        //       ? evt?.chart?.legend?.legendItems[array[0].datasetIndex].text
        //       : "";
        //   let rawObj =
        //     this.rawData?.datasets &&
        //     this.rawData?.datasets[array[0].datasetIndex]
        //       ? this.rawData?.datasets[array[0].datasetIndex]
        //       : evt?.chart?.legend?.legendItems &&
        //         evt?.chart?.legend?.legendItems[array[0].datasetIndex] &&
        //         evt?.chart?.legend?.legendItems[array[0].datasetIndex].text
        //       ? evt?.chart?.legend?.legendItems[array[0].datasetIndex].text
        //       : "";
        //   if (groupByObj?.label) {
        //     let label = groupByObj?.label;
        //     if (
        //       rawObj?.label &&
        //       rawObj.label.includes("#") &&
        //       rawObj.label.split("#")[1]
        //     ) {
        //       label = rawObj.label.split("#")[1];
        //     }
        //     this.filters.push({
        //       field: this.componentData.pie_chart_field_group_by,
        //       operator: "in",
        //       value: [label],
        //       value_type: "",
        //       value_source: "",
        //       data_type: "STRING",
        //       query_type: "AND",
        //       value_field: "",
        //       validations: {
        //         data_type: "",
        //       },
        //       relation_entity_id: "",
        //       data_source: "field_option",
        //     });
        //   }
        // }
        //   this.tableVisible = true;
        // }
      }
    },
    handleDialogClose() {
      this.tableVisible = false;
    },
    createFilter(field, evt, array, isGroupBy) {
      let label = "",
        groupByObj;
      if (isGroupBy) {
        groupByObj =
          this.rawData?.datasets &&
          this.rawData?.datasets[array[0].datasetIndex]
            ? this.rawData?.datasets[array[0].datasetIndex]
            : "";
      } else {
        groupByObj =
          this.rawData?.labels && this.rawData?.labels[array[0].index]
            ? this.rawData?.labels[array[0].index]
            : "";
      }
      if (groupByObj) {
        label = groupByObj.label ? groupByObj.label : groupByObj;
      }
      // let isEntity = this.checkFieldType(field, "ENTITY");
      // let rawObj =
      //   this.rawData?.datasets && this.rawData?.datasets[array[0].datasetIndex]
      //     ? this.rawData?.datasets[array[0].datasetIndex]
      //     : evt?.chart?.legend?.legendItems &&
      //       evt?.chart?.legend?.legendItems[array[0].datasetIndex] &&
      //       evt?.chart?.legend?.legendItems[array[0].datasetIndex].text
      //     ? evt?.chart?.legend?.legendItems[array[0].datasetIndex].text
      //     : "";
      // let mainLabel =
      //   this.chartData?.labels && this.chartData?.labels[array[0].index]
      //     ? this.chartData?.labels[array[0].index]
      //     : evt?.chart?.legend?.legendItems &&
      //       evt?.chart?.legend?.legendItems[array[0].index] &&
      //       evt?.chart?.legend?.legendItems[array[0].index].text
      //     ? evt?.chart?.legend?.legendItems[array[0].index].text
      //     : "";
      // let label = isGroupBy
      //   ? groupByObj && groupByObj.label
      //     ? groupByObj.label
      //     : ""
      //   : mainLabel;
      if (array[0].datasetIndex == 0 && label == "Total") {
        return null;
      }
      if (label) {
        if (label && label.includes("#") && label.split("#")[0]) {
          label = label.split("#")[0];
        }
        let sum_field =
          this.componentData.sum_fields && this.componentData.sum_fields[0]
            ? this.componentData.sum_fields[0]
            : null;
        if (sum_field) {
          let sumLabel = this.getLabel(sum_field);
          label = label.replace("-" + sumLabel, "");
        }
        // if (isEntity) {
        //   (this.entityData || []).forEach((item) => {
        //     if (item.entity_label == label) {
        //       label = item._id;
        //     }
        //   });
        // }
        return {
          field: field,
          operator: "in",
          value: [label],
          value_type: "",
          value_source: "",
          data_type: "STRING",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          relation_entity_id: "",
          data_source: "field_option",
        };
      } else {
        return null;
      }
    },
    applyDateFilter(value) {
      let filters = [];
      if (value) {
        this.$emit("tableUpdate", {
          index: this.index,
          data: this.componentData,
          component: "CHART",
        });
        if (value == "custom_date" || value == "custom_date_range") {
          return;
        }
        let filter = this.getDatePickerFilter(value);
        filters.push(filter);
      }
      this.applied_date_filters = [];
      this.applied_date_filters = filters;
      filters = [...filters, ...this.applied_quick_filters];
      this.getNecessaryInfo(filters);
    },
    getDatePickerFilter(value) {
      let values = this.getDatePickerHelper(value);
      return {
        field: this.componentData.date_filter_field,
        operator: "real_time",
        value: values.value,
        value_type: "",
        value_source: "",
        data_type: "DATE",
        query_type: "AND",
        value_field: "",
        validations: {
          data_type: "",
        },
        selected_date_picker: value,
        today: values.today,
      };
    },
    applyCustomDateFilter(value) {
      if (value) {
        let dateValue = this.getDateByDays(0, value);
        let filter = [
          {
            data_type: "DATE",
            field: this.componentData.date_filter_field,
            operator: "=",
            selected_date_picker: "custom_date",
            today: "",
            value_field: "",
            value_source: "",
            query_type: "OR",
            validations: {
              data_type: "",
            },
            value_type: "VALUE",
            every_year: "",
            entity_id: "",
            value: dateValue,
          },
        ];
        this.getNecessaryInfo(filter);
      }
    },
    applyCustomDateRangeFilter(value) {
      if (value.length) {
        let dateValue = this.getDateByDays(0, value[0]);
        let dateValue2 = this.getDateByDays(0, value[1]);
        let filter = [
          {
            data_type: "DATE",
            field: this.componentData.date_filter_field,
            operator: "real_time",
            selected_date_picker: "custom_date_range",
            value_field: "",
            value_source: "",
            query_type: "OR",
            validations: {
              data_type: "",
            },
            value_type: "VALUE",
            every_year: "",
            entity_id: "",
            value: dateValue,
            today: dateValue2,
          },
        ];
        this.getNecessaryInfo(filter);
      }
    },
    deleteComponent() {
      this.$confirm("Are you sure to delete the chart?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    resetModal() {
      // this.chartData.labels = [];
      // this.chartData.datasets[0].data = [];
      // this.chartData.datasets[0].backgroundColor = [];
      // this.chartData.datasets[0].label = "";
    },
    async updateChartType() {
      let filters = [];
      if (
        this.componentData.customFilers &&
        this.componentData.customFilers.length
      ) {
        filters = [...this.componentData.customFilers];
      }
      await this.getNecessaryInfo(filters);
      this.$emit("tableUpdate", {
        index: this.index,
        data: this.componentData,
        component: "CHART",
      });
    },
    async updateComponent() {
      let ex = this.customDashboardData.allTableComponents.filter(
        (e, i) =>
          i !== this.index &&
          e.component_name.toLowerCase() ==
            this.updateComponentData.component_name.toLowerCase()
      );
      if (ex && ex.length) {
        this.$notify.error({
          title: "Error",
          message: "Component name already exists",
        });
        return;
      }
      if (
        JSON.stringify(this.updateComponentData) !=
        JSON.stringify(this.componentData)
        // this.updateComponentData.entity_id != this.componentData.entity_id ||
        // this.updateComponentData.pie_chart_field !=
        //   this.componentData.pie_chart_field ||
        // this.updateComponentData.chart_type != this.componentData.chart_type ||
        // this.updateComponentData.pie_chart_field_group_by !=
        //   this.componentData.pie_chart_field_group_by ||
        // this.updateComponentData.sum_field != this.componentData.sum_field ||
        // this.updateComponentData.sum_fields != this.componentData.sum_fields
      ) {
        this.componentData = { ...this.updateComponentData };
        await this.getNecessaryInfo();
      } else {
        this.componentData = { ...this.updateComponentData };
      }
      this.$emit("tableUpdate", {
        index: this.index,
        data: this.componentData,
        component: "CHART",
      });
      this.openDialogVisible = false;
    },
    openConfigDialog() {
      this.updateComponentData = {};
      this.updateComponentData = { ...this.componentData };
      this.openDialogVisible = true;
    },
    getRandomColor(existColors) {
      let filterdColors = [];
      let indx = Math.floor(Math.random() * (23 - 0 + 1) + 0);
      if (existColors && existColors.length) {
        filterdColors = this.colors.filter(
          (color) => existColors.indexOf(color) == -1
        );
        if (filterdColors && filterdColors.length) {
          indx = Math.floor(Math.random() * filterdColors.length);
        } else {
          filterdColors = [];
          filterdColors = this.colors.filter(
            (color) => existColors.indexOf(color) == -1
          );
          indx = Math.floor(Math.random() * filterdColors.length);
        }
      } else {
        filterdColors = this.colors;
      }
      return filterdColors[indx];
    },
    generateRandomColors(max) {
      let result = [];
      if (max) {
        for (let i = 0; i < max; i++) {
          let userColor = this.getRandomColor(result);
          result.push(userColor);
        }
      }
      return result;
    },
    getChartLabels(data, field, filterLabels) {
      let result = [];
      let tid = field.split("#")[0];
      let fd = field.split("#")[1];
      let selectedField = (this.currentEntityFields || []).find(
        (e) => e.key == field
      );
      let isDate =
        selectedField && selectedField.inputType == "DATE" ? true : false;
      if (isDate) {
        if (this.componentData?.selectedDateRange == "YEARLY") {
          if (this.currentYearSpan && this.currentYearSpan.length) {
            result = [...this.currentYearSpan];
          } else {
            let currentYear = new Date().getFullYear();
            for (let i = currentYear; i >= currentYear - 10; i--) {
              result.push(i.toString());
            }
            result.reverse();
            this.$set(this.componentData, "startYear", result[0]);
            this.$set(this.componentData, "endYear", result[result.length - 1]);
            this.currentYearSpan = [...result];
          }
        } else {
          result = this.months;
        }
      } else {
        data.forEach((item) => {
          if (
            item.entityData &&
            item.entityData[tid] &&
            item.entityData[tid][fd]
          ) {
            if (
              typeof item.entityData[tid][fd] == "object" &&
              item.entityData[tid][fd].length
            ) {
              item.entityData[tid][fd].forEach((fl) => {
                let existedDataIndex = (result || []).findIndex((e) => e == fl);
                if (existedDataIndex == -1) {
                  result.push(fl);
                }
              });
            } else {
              let existedDataIndex = (result || []).findIndex((e) => {
                if (e.includes("#")) {
                  return e.split("#")[1] == item.entityData[tid][fd];
                } else {
                  return e == item.entityData[tid][fd];
                }
              });
              if (existedDataIndex == -1) {
                if (item.entityData[tid][fd + "/name"]) {
                  result.push(
                    item.entityData[tid][fd + "/name"] +
                      "#" +
                      item.entityData[tid][fd]
                  );
                } else {
                  result.push(item.entityData[tid][fd]);
                }
              }
            }
          }
        });
        if (filterLabels && filterLabels.length) {
          result = result.filter((e) => {
            if (e && e.includes("#")) {
              return filterLabels.indexOf(e.split("#")[1]) != -1;
            }
            return filterLabels.indexOf(e) != -1;
          });
        }
      }
      return result;
    },
    getDataSets(labels, data) {
      if (this.componentData?.pie_chart_field_group_by) {
        let tid = this.componentData.pie_chart_field_group_by.split("#")[0];
        let fd = this.componentData.pie_chart_field_group_by.split("#")[1];
        let sum_field =
          this.componentData.sum_fields && this.componentData.sum_fields[0]
            ? this.componentData.sum_fields[0]
            : "";
        let result = [];
        if (this.componentData?.include_chart_total) {
          result = [
            ...result,
            ...this.getChartCount(
              labels,
              data,
              this.componentData.pie_chart_field,
              sum_field,
              "Total"
            ),
          ];
        }
        let groupLabels = this.getChartLabels(
          data,
          this.componentData.pie_chart_field_group_by,
          this.componentData.pie_chart_field_group_by_selected_options
        );
        (groupLabels || []).forEach((e) => {
          let value = e && e.includes("#") ? e.split("#")[1] : e;
          let counts = data.filter((item) => {
            if (
              item.entityData &&
              item.entityData[tid] &&
              item.entityData[tid][fd]
            ) {
              if (
                typeof item.entityData[tid][fd] == "object" &&
                item.entityData[tid][fd].length
              ) {
                let found = item.entityData[tid][fd].find((fl) => value == fl);
                return found ? true : false;
              } else if (item.entityData[tid][fd] == value) {
                return true;
              }
            }
          });
          let dataSet = this.getChartCount(
            labels,
            counts,
            this.componentData.pie_chart_field,
            sum_field,
            e && e.includes("#") ? e.split("#")[0] : e
          );
          result = [...result, ...dataSet];
        });
        return result;
      } else if (
        this.componentData?.sum_fields &&
        this.componentData?.sum_fields.length > 1
      ) {
        let result = [];
        (this.componentData?.sum_fields || []).forEach((sum_field) => {
          let dataSet = this.getChartCount(
            labels,
            data,
            this.componentData.pie_chart_field,
            sum_field,
            this.getLabel(sum_field)
          );
          result = [...result, ...dataSet];
        });
        return result;
      } else {
        let sum_field =
          this.componentData.sum_fields && this.componentData.sum_fields[0]
            ? this.componentData.sum_fields[0]
            : "";
        let result = this.getChartCount(
          labels,
          data,
          this.componentData.pie_chart_field,
          sum_field
        );
        return result;
      }
    },
    getChartCount(labels, data, field, sum_field, label = "") {
      let tid = field.split("#")[0];
      let fd = field.split("#")[1];
      let counts = [];
      let selectedField = (this.currentEntityFields || []).find(
        (e) => e.key == field
      );
      let isDate =
        selectedField && selectedField.inputType == "DATE" ? true : false;
      labels.forEach((e) => {
        let value = e && e.includes("#") ? e.split("#")[1] : e;
        let count = data.filter((item) => {
          if (
            item.entityData &&
            item.entityData[tid] &&
            item.entityData[tid][fd]
          ) {
            if (isDate) {
              const date = moment(item.entityData[tid][fd]);
              const year = date.format("YYYY");
              if (this.componentData?.selectedDateRange == "YEARLY") {
                return year == value;
              } else {
                if (year == this.currentyear && date.format("MMMM") == value) {
                  return true;
                }
                return false;
              }
            } else if (
              typeof item.entityData[tid][fd] == "object" &&
              item.entityData[tid][fd].length
            ) {
              let found = item.entityData[tid][fd].find((fl) => value == fl);
              return found ? true : false;
            } else if (item.entityData[tid][fd] == value) {
              return true;
            }
          }
        });
        if (sum_field && sum_field.includes("#")) {
          let [sumTempId, sumField] = sum_field.split("#");
          let total = 0;
          (count || []).forEach((dt) => {
            if (
              dt?.entityData &&
              dt.entityData[sumTempId] &&
              dt.entityData[sumTempId][sumField]
            ) {
              total = total + parseInt(dt.entityData[sumTempId][sumField]);
            }
          });
          counts.push(total);
        } else {
          counts.push(count && count.length ? count.length : 0);
        }
      });
      let colors = [];
      if (
        this.componentData?.chart_type == "DOUGHNUT" ||
        this.componentData?.chart_type == "PIE"
      ) {
        if (this.savedColors.length) {
          colors = this.savedColors;
        } else {
          colors = this.generateRandomColors(this.chartData.labels.length);
          this.savedColors = colors;
        }
      } else {
        let [color] = this.generateRandomColors(1);
        colors = Array(this.chartData.labels.length).fill(color);
      }
      let dataLabel = label
        ? label +
          (sum_field && this.getLabel(sum_field)
            ? "-" + this.getLabel(sum_field)
            : "")
        : this.currentEntity && this.currentEntity.name
        ? this.getLabel(sum_field)
        : this.getLabel(field);
      return [
        {
          label: dataLabel,
          backgroundColor: colors,
          data: counts,
          currency_field: sum_field ? this.getField(sum_field) : false,
        },
      ];
    },
    getLabel(field) {
      if (
        this.currentEntityFields &&
        this.currentEntityFields.length &&
        field
      ) {
        let foundField = this.currentEntityFields.find((e) => e.key == field);
        return foundField && foundField.label ? foundField.label : "";
      }
      return "";
    },
    getField(field) {
      if (
        this.currentEntityFields &&
        this.currentEntityFields.length &&
        field
      ) {
        let foundField = this.currentEntityFields.find((e) => e.key == field);
        return foundField ? foundField : null;
      }
      return null;
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key = data._id + "#" + e.key;
        return e;
      });
      return fields;
    },
    async getNecessaryInfo(customFilters = []) {
      this.loading = true;
      this.refresh = true;
      if (this.componentData?.chart_selected_filter) {
        await this.$store.dispatch(
          "filters/getFilterById",
          this.componentData.chart_selected_filter
        );
        this.currentFilter = this.getSingleFilterData;
        if (this.getSingleFilterData?.filters) {
          customFilters = [
            ...customFilters,
            ...this.getSingleFilterData.filters,
          ];
        }
      }
      // this.resetModal();
      let params = {};
      (params.entity_id = this.componentData.entity_id),
        (params.group_by = this.componentData.pie_chart_field);
      if (customFilters && customFilters.length) {
        params.customFilters = this.mapDateFilters(customFilters);
      }
      if (this.componentData?.is_relational_data) {
        params.customFilters = this.applyRelationalFilter(customFilters);
      }
      if (this.componentData?.pie_chart_field_group_by) {
        params.sub_group_by = this.componentData.pie_chart_field_group_by;
      }
      if (this.componentData.sum_fields) {
        params.sum_fields = this.componentData.sum_fields;
      }
      if (this.checkIsDate) {
        if (this.componentData?.selectedDateRange !== "YEARLY") {
          params.seletedYear = parseInt(
            moment(this.componentData.startYear).format("YYYY")
          );
        }
        params.isDate = true;
      }
      this.entityData = [];
      // await this.$store.dispatch(
      //   "entities/fetchCustomDashboardPieStats",
      //   params
      // );
      params["customFilters"] = this.addGlobalFilters(
        params.customFilters || [],
        this.componentData.entity_id,
        this.checkGlobalFilterOnSingleStat(this.componentData.entity_id)
      );
      const [response] = await Promise.all([
        axios.post("/entities-data/charts", params),
        this.$store.dispatch("entities/fetchEntityById", {
          entity_id: this.componentData.entity_id,
        }),
      ]);
      if (this.getEntityDataById) {
        if (this.getEntityDataById) {
          this.currentEntity = this.getEntityDataById;
          (this.currentEntity.templates || []).forEach((e) => {
            if (e && e.template_id && !e.is_repeatable) {
              this.currentEntityFields = [
                ...this.currentEntityFields,
                ...this.getTemplateFields(e.templateInfo),
              ];
            }
          });
        }
      }
      this.$emit("tableUpdate", {
        index: this.index,
        data: this.componentData,
        component: "CHART",
      });
      this.customDashboardPieStats = { ...response.data };
      if (this.customDashboardPieStats && this.customDashboardPieStats.data) {
        this.entityData = this.customDashboardPieStats.data;
        this.customDashboardPieStats.data =
          this.customDashboardPieStats.data.map((e) => {
            if (e?.properties) {
              let value = (e.properties || []).find((e) => e.entity_label);
              if (value) {
                e.entity_label = value.entity_label;
              }
            }
            return e;
          });
        // this.mapCalendarData();
        this.newMappedData();
        this.refresh = false;
        this.loading = false;
      } else {
        setTimeout(() => {
          this.refresh = false;
        }, 100);
        this.loading = false;
      }
    },
    async newMappedData() {
      this.loading = true;
      this.refresh = true;
      this.savedColors = [];
      this.chartData.labels = [];
      this.chartData.datasets = [];
      if (
        this.componentData &&
        this.componentData.pie_chart_field_options &&
        this.componentData.pie_chart_field_options.length
      ) {
        let data = [];
        for (
          let i = 0;
          i < this.componentData.pie_chart_field_options.length;
          i++
        ) {
          let dt = this.customDashboardPieStats.data.find(
            (d) => d._id == this.componentData.pie_chart_field_options[i]
          );
          if (dt != undefined) {
            data.push(dt);
          }
        }
        this.customDashboardPieStats.data = data;
      }
      this.chartData.labels = this.getGroupLables(
        this.customDashboardPieStats.data
      );
      this.chartData.datasets = this.getGroupDatasets(
        this.customDashboardPieStats.data,
        this.componentData.pie_chart_field,
        this.chartData.labels
      );
      this.rawData = JSON.parse(JSON.stringify(this.chartData));
      this.chartData.labels = this.chartData.labels.map((e) => {
        if (e.includes("#")) {
          return e.split("#")[1];
        } else {
          return e;
        }
      });
      this.chartData.datasets = this.chartData.datasets.map((e) => {
        if (e.label && e.label.includes("#")) {
          e.label = e.label.split("#")[1];
        }
        return e;
      });
      setTimeout(() => {
        this.loading = false;
        this.refresh = false;
      }, 100);
    },
    getGroupDatasets(data, field, labels) {
      if (this.componentData?.pie_chart_field_group_by) {
        let result = [],
          sub_group_by = [];
        (data || []).forEach((el) => {
          if (el?.properties) {
            (el.properties || []).forEach((e) => {
              if (
                e?.label &&
                sub_group_by.indexOf(
                  e.label + (e.sub_entity_label ? "#" + e.sub_entity_label : "")
                ) == -1
              ) {
                if (
                  this.componentData
                    ?.pie_chart_field_group_by_selected_options &&
                  this.componentData.pie_chart_field_group_by_selected_options
                    .length
                ) {
                  if (
                    this.componentData.pie_chart_field_group_by_selected_options.indexOf(
                      e.label
                    ) != -1
                  ) {
                    sub_group_by.push(
                      e.label +
                        (e.sub_entity_label ? "#" + e.sub_entity_label : "")
                    );
                  }
                } else {
                  sub_group_by.push(
                    e.label +
                      (e.sub_entity_label ? "#" + e.sub_entity_label : "")
                  );
                }
              }
            });
          }
        });
        (sub_group_by || []).forEach((sub_label) => {
          let counts = [];
          (labels || []).forEach((label) => {
            if (
              this.checkIsDate &&
              this.componentData?.selectedDateRange !== "YEARLY"
            ) {
              label = this.months.indexOf(label) + 1;
            }
            let group = (data || []).find(
              (e) =>
                e._id + (e.entity_label ? "#" + e.entity_label : "") == label
            );
            if (
              group?.properties &&
              (group.properties || []).findIndex(
                (o) =>
                  o.label +
                    (o.sub_entity_label ? "#" + o.sub_entity_label : "") ==
                  sub_label
              ) != -1
            ) {
              let property = (group.properties || []).find(
                (o) =>
                  o.label +
                    (o.sub_entity_label ? "#" + o.sub_entity_label : "") ==
                  sub_label
              );
              if (
                this.componentData.sum_fields &&
                this.componentData.sum_fields.length
              ) {
                let sum_field =
                  this.componentData.sum_fields &&
                  this.componentData.sum_fields[0]
                    ? this.componentData.sum_fields[0]
                    : null;
                counts.push(property[sum_field] || 0);
              } else {
                counts.push(property.count);
              }
            } else {
              counts.push(0);
            }
          });
          let sum_field;
          if (
            this.componentData.sum_fields &&
            this.componentData.sum_fields.length
          ) {
            sum_field =
              this.componentData.sum_fields && this.componentData.sum_fields[0]
                ? this.componentData.sum_fields[0]
                : null;
          }
          result.push({
            label: sub_label,
            backgroundColor: this.generateRandomColors(1),
            data: counts,
            currency_field: sum_field ? this.getField(sum_field) : false,
          });
        });
        return result;
      } else if (this.checkIsDate) {
        if (
          this.componentData.sum_fields &&
          this.componentData.sum_fields.length
        ) {
          let result = [];
          (this.componentData.sum_fields || []).forEach((el) => {
            let mappedData = [];
            if (this.componentData?.selectedDateRange === "YEARLY") {
              labels.forEach((e) => {
                let yearData = data.find((dt) => dt?._id == e);
                mappedData.push(yearData?.[el] ? yearData[el] : 0);
              });
            } else {
              labels.forEach((e, i) => {
                let yearData = data.find((el) => el?._id == i + 1);
                mappedData.push(yearData?.[el] ? yearData[el] : 0);
              });
            }
            result.push({
              label: this.getLabel(el),
              backgroundColor: this.generateRandomColors(1),
              data: mappedData,
              currency_field: el ? this.getField(el) : false,
            });
          });
          return result;
        } else {
          let mappedData = [];
          if (this.componentData?.selectedDateRange === "YEARLY") {
            labels.forEach((e) => {
              let yearData = data.find((el) => el?._id == e);
              mappedData.push(yearData?.count ? yearData.count : 0);
            });
          } else {
            labels.forEach((e, i) => {
              let yearData = data.find((el) => el?._id == i + 1);
              mappedData.push(yearData?.count ? yearData.count : 0);
            });
          }
          return [
            {
              label: this.getLabel(field),
              backgroundColor: this.generateRandomColors(mappedData.length),
              data: mappedData,
            },
          ];
        }
      }
      let colors = [];
      //generating colors for PIE & DOUGHNUT without groupBy and sub-groupBy
      if (
        this.componentData?.chart_type == "DOUGHNUT" ||
        this.componentData?.chart_type == "PIE"
      ) {
        if (this.savedColors.length) {
          colors = this.savedColors;
        } else {
          colors = this.generateRandomColors(data.length);
          this.savedColors = colors;
        }
        if (
          this.componentData.sum_fields &&
          this.componentData.sum_fields.length
        ) {
          let result = [];
          (this.componentData.sum_fields || []).forEach((el) => {
            result.push({
              label: this.getLabel(el),
              backgroundColor: colors,
              data: (data || []).flatMap((e) => e[el]),
              currency_field: el ? this.getField(el) : false,
            });
          });
          return result;
        } else {
          return [
            {
              label: this.getLabel(field),
              backgroundColor: colors,
              data: (data || []).flatMap((e) => e.count),
            },
          ];
        }
      } else {
        if (
          this.componentData.sum_fields &&
          this.componentData.sum_fields.length
        ) {
          let result = [];
          (this.componentData.sum_fields || []).forEach((el) => {
            result.push({
              label: this.getLabel(el),
              backgroundColor: this.generateRandomColors(
                this.componentData.sum_fields.length
              ),
              data: (data || []).flatMap((e) => e[el]),
              currency_field: el ? this.getField(el) : false,
            });
          });
          return result;
        } else {
          //generating single color for Bar & Line graphs without groupBy and sub-groupBy
          return [
            {
              label: this.getLabel(field),
              backgroundColor: this.generateRandomColors(1),
              data: (data || []).flatMap((e) => e.count),
            },
          ];
        }
      }
    },
    getGroupLables(data) {
      let result = [];
      if (this.checkIsDate) {
        if (this.componentData?.selectedDateRange == "YEARLY") {
          if (this.currentYearSpan && this.currentYearSpan.length) {
            result = [...this.currentYearSpan];
          } else {
            let currentYear = new Date().getFullYear();
            for (let i = currentYear; i >= currentYear - 10; i--) {
              result.push(i.toString());
            }
            result.reverse();
            this.$set(this.componentData, "startYear", result[0]);
            this.$set(this.componentData, "endYear", result[result.length - 1]);
            this.currentYearSpan = [...result];
          }
        } else {
          result = this.months;
        }
        return result;
      }
      return (data || []).flatMap(
        (e) => e._id + (e.entity_label ? "#" + e.entity_label : "")
      );
    },
    async mapCalendarData() {
      this.loading = true;
      this.refresh = true;
      this.savedColors = [];
      this.chartData.labels = [];
      this.chartData.datasets = [];
      this.chartData.labels = this.getChartLabels(
        this.customDashboardPieStats.data,
        this.componentData.pie_chart_field,
        []
      );
      this.chartData.datasets = this.getDataSets(
        this.chartData.labels,
        this.customDashboardPieStats.data
      );
      this.rawData = JSON.parse(JSON.stringify(this.chartData));
      this.chartData.labels = this.chartData.labels.map((e) => {
        if (e.includes("#")) {
          return e.split("#")[0];
        } else {
          return e;
        }
      });
      this.chartData.datasets = this.chartData.datasets.map((e) => {
        if (e.label && e.label.includes("#")) {
          e.label = e.label.split("#")[0];
        }
        return e;
      });
      setTimeout(() => {
        this.loading = false;
        this.refresh = false;
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.default-profile {
  display: flex;
  width: 100%;
}
.left-card {
  flex: 1;
  width: 100%;
  margin-top: 10px;
}
.right-card {
  flex: 3;
  width: 100%;
}
.custom-dashboard-table {
  border: 1px solid #eaeaea;
  min-height: 310px !important;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
}
.pie-body {
  text-align: center !important;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  background-color: #ffffff;
}
.pie-no-data {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
</style>
